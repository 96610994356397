



































import Vue from 'vue';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    accordionsExpaned: [] as boolean[],
  }),
  computed: {
    acceptedAllConditions(): boolean {
      // return this.innerValue.acceptFilledOutTruthful
      //   && this.innerValue.newsletter
      //   && this.innerValue.acceptGsStatutes
      //   && this.innerValue.acceptGdpr;
      return true;
    },
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
});
